import { FC, useEffect, useRef } from 'react';
import { Checkbox, DatePicker, Flex, Input, Select, Typography } from 'antd';
import {
  ErrorMessage,
  LabelTitle,
  MailCheckbox,
  ModalDialog,
  StatusDropdown,
  UploadButton,
} from '..';
import { IProps } from './type';
import styles from './index.module.scss';
import globalStyles from '../../assets/styles/index.module.scss';
import dayjs from 'dayjs';
import { getValueFromEnum } from '../../utils/functions';
import { CheckSibaOrganization } from '../../helpers/Organization';
import { useAppSelector } from '../../store';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DATE_FORMAT } from '../../assets/conts';

dayjs.extend(customParseFormat);

const MotorInsuranceModal: FC<IProps> = (props) => {
  const { getFieldProps, setFieldValue, values, errors, touched } =
    props.formik;
  const uploadInput = useRef<HTMLInputElement | null>(null);
  const { organization } = useAppSelector(
    (state) => state.userReducer.userInfo,
  );

  const handleChangeCheckbox = (e: CheckboxChangeEvent, field: string) => {
    setFieldValue(
      'branch',
      e.target.checked
        ? [...values.branch, field]
        : values.branch.filter((item) => item !== field),
    );
  };

  useEffect(() => {
    if (props.open && !values.organization && !!organization) {
      console.log(props.open);
      setFieldValue('organization', organization);
    }
  }, [props.open, values.organization, organization]);

  return (
    <ModalDialog
      title={props.title}
      subTitle={props.subTitle}
      open={props.open}
      handleOk={props.handleSubmit}
      handleCancel={props.handleCancel}
    >
      <Flex gap="middle" vertical>
        <Flex align="flex-start" justify="space-between">
          <LabelTitle>Firma Bilgileri</LabelTitle>
          <Flex vertical gap="small">
            {props.type === 'CANCEL' ? (
              <Typography className={styles.labelValue}>
                {
                  props.organizations.find(
                    (item) => item.id === (values.organization || organization),
                  )?.name
                }
              </Typography>
            ) : (
              <Select
                id="organization"
                value={
                  props.organizations.find(
                    (item) => item.id === (values.organization || organization),
                  )?.name || undefined
                }
                placeholder="Firma Seçiniz"
                style={{ width: 200 }}
                disabled={!CheckSibaOrganization()}
                options={props.organizations.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                onChange={(value) => setFieldValue('organization', value)}
              />
            )}
          </Flex>
        </Flex>
        {props.type === 'CREATE' ? (
          <Flex gap="small" vertical>
            <LabelTitle>Poliçe Bilgileri</LabelTitle>
            <Flex vertical gap="small">
              <Flex justify="space-between">
                <Checkbox
                  checked={values.branch.includes('insurance')}
                  onChange={(e: CheckboxChangeEvent) =>
                    handleChangeCheckbox(e, 'insurance')
                  }
                >
                  Kasko
                </Checkbox>
                <Checkbox
                  checked={values.branch.includes('traffic')}
                  onChange={(e: CheckboxChangeEvent) =>
                    handleChangeCheckbox(e, 'traffic')
                  }
                >
                  Trafik
                </Checkbox>
                <Checkbox
                  checked={values.branch.includes('imm')}
                  onChange={(e: CheckboxChangeEvent) =>
                    handleChangeCheckbox(e, 'imm')
                  }
                >
                  İMM
                </Checkbox>
                <Checkbox
                  checked={values.branch.includes('green_card')}
                  onChange={(e: CheckboxChangeEvent) =>
                    handleChangeCheckbox(e, 'green_card')
                  }
                >
                  Yeşil Kart (Yurtdışı)
                </Checkbox>
              </Flex>
              {touched.branch && errors.branch && (
                <ErrorMessage>{errors.branch}</ErrorMessage>
              )}
            </Flex>
          </Flex>
        ) : (
          <Flex justify="space-between">
            <LabelTitle>Poliçe Tipi</LabelTitle>
            <Typography className={styles.labelValue}>
              {getValueFromEnum(props.branches, values.branch[0])}
            </Typography>
          </Flex>
        )}
        <Flex gap="small" vertical>
          {props.type === 'CANCEL' ? (
            <>
              <LabelTitle>Gerekli Belgeler</LabelTitle>
              <Typography className={styles.documentDescription}>
                * Oto sigortaları sadece aracın satılması halinde bitiş
                tarihinden önce revize edilebilmektedir. Poliçe iptali için
                lütfen noter satış belgesini yükleyiniz. Satılan araca ait kasko
                ve trafik sigortası poliçelerinizin iptali sağlanacaktır.
                Satılan aracın yerine yeni bir araç satın aldıysanız yeni poliçe
                talebi menüsünden talebinizi ayrıca iletiniz. İptal talebi ile
                birlikte yeni poliçe talebi iletmeniz halinde kasko poliçeniz
                için iptal yapılmadan araç değişikliği zeyilnamesi
                düzenlenebilecektir.
              </Typography>
            </>
          ) : (
            <>
              <LabelTitle>Gerekli Belgeler</LabelTitle>
              <Typography className={styles.documentDescription}>
                * Mevcut araçlarınız veya ikinci el olarak satın alınan
                araçlarınız için belge seri numarası görünecek şekilde araç
                ruhsatı ekleyiniz.
              </Typography>
              <Typography className={styles.documentDescription}>
                * Sıfır araçlarınız için araç faturasını ekleyiniz ve açıklama
                alanında aracın il plaka kodunu belirtiniz.
              </Typography>
            </>
          )}
        </Flex>
        <UploadButton
          fileName={[...values.upload_files]?.map((item) => item.name)}
          uploadRef={uploadInput}
          onChange={props.handleUploadFile}
        />
        <Flex justify="end">
          {touched.upload_files && errors.upload_files && (
            <ErrorMessage>{errors.upload_files.toString()}</ErrorMessage>
          )}
        </Flex>
        <Flex gap="small" vertical>
          <LabelTitle>Açıklama</LabelTitle>
          <Input.TextArea
            placeholder="Açıklama"
            {...getFieldProps('description')}
          />
          {touched.description && errors.description && (
            <ErrorMessage>{errors.description}</ErrorMessage>
          )}
        </Flex>
        {props.type === 'EDIT' && (
          <Flex justify="space-between">
            <LabelTitle>Durum Bilgisi</LabelTitle>
            <StatusDropdown
              status={values.recent_status}
              statusChoices={props.statusChoices}
              onChange={(value) => setFieldValue('recent_status', value)}
            />
          </Flex>
        )}
        {props.type !== 'CANCEL' && (
          <Flex gap="small" vertical>
            <LabelTitle>Tarih Aralıgı</LabelTitle>
            <Flex justify="space-between" gap="small">
              <Flex vertical gap="small" className={globalStyles.widthHalf}>
                <DatePicker
                  id="start_date"
                  name="start_date"
                  format={DATE_FORMAT}
                  value={
                    values.start_date
                      ? dayjs(values.start_date, DATE_FORMAT)
                      : null
                  }
                  placeholder="Başlangıç Tarihi"
                  onChange={(date) => {
                    if (date) {
                      setFieldValue('start_date', date.format(DATE_FORMAT));
                    }
                  }}
                />
                {touched.start_date && errors.start_date && (
                  <ErrorMessage>{errors.start_date}</ErrorMessage>
                )}
              </Flex>
              <Flex vertical gap="small" className={globalStyles.widthHalf}>
                <DatePicker
                  id="end_date"
                  name="end_date"
                  format={DATE_FORMAT}
                  value={
                    values.end_date ? dayjs(values.end_date, DATE_FORMAT) : null
                  }
                  placeholder="Bitiş Tarihi"
                  onChange={(date) => {
                    if (date) {
                      setFieldValue('end_date', date.format(DATE_FORMAT));
                    }
                  }}
                />
                {touched.end_date && errors.end_date && (
                  <ErrorMessage>{errors.end_date}</ErrorMessage>
                )}
              </Flex>
            </Flex>
          </Flex>
        )}
        {props.type === 'EDIT' && (
          <MailCheckbox
            checked={values.email_permission}
            onChange={(e: CheckboxChangeEvent) =>
              setFieldValue('email_permission', e.target.checked)
            }
          />
        )}
      </Flex>
    </ModalDialog>
  );
};

export default MotorInsuranceModal;
