import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Flex, Menu } from 'antd';
import {
  CaretDownOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
} from '@ant-design/icons';
import { MenuInfo } from 'rc-menu/lib/interface';
import { getItems, getRootSubmenuKeys } from './helpers';
import styles from './index.module.scss';
import { useAppDispatch, useAppSelector } from '../../store';
import { layoutActions } from '../../store/reducers/layoutReducer';

const Sidemenu: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const permissions = useAppSelector((state) => state.userReducer.permissions);
  const isSideMenuCollapsed = useAppSelector(
    (state) => state.layoutReducer.isSideMenuCollapsed,
  );

  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const handleMenuClick = ({ key }: MenuInfo) => {
    if (key) {
      navigate(key);
    }
  };

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    if (latestOpenKey && getRootSubmenuKeys().includes(latestOpenKey)) {
      setOpenKeys([latestOpenKey]);
    } else {
      setOpenKeys(keys);
    }
  };

  const handleChangeCollapsed = () => {
    dispatch(layoutActions.setSideMenuCollapse(!isSideMenuCollapsed));
  };

  return (
    <Flex
      vertical
      align="end"
      className={styles.container}
      style={{
        width: isSideMenuCollapsed ? 80 : 258,
        transition: 'width 0.2s',
      }}
    >
      <div style={{ position: 'absolute', top: 75, marginRight: '-11px' }}>
        <Button
          type="dashed"
          size="small"
          style={{ height: 20, width: 20, borderRadius: 100 }}
          onClick={handleChangeCollapsed}
        >
          {isSideMenuCollapsed ? (
            <DoubleRightOutlined />
          ) : (
            <DoubleLeftOutlined />
          )}
        </Button>
      </div>
      <Menu
        defaultSelectedKeys={['/']}
        mode="inline"
        theme="dark"
        selectedKeys={[location.pathname]}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        inlineCollapsed={isSideMenuCollapsed}
        expandIcon={<CaretDownOutlined />}
        items={getItems(
          permissions.filter((permission) => permission.action === 'view'),
        )}
        className={styles.menu}
        onClick={handleMenuClick}
      />
    </Flex>
  );
};

export default Sidemenu;
