import { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Flex } from 'antd';
import {
  AuthorizedButton,
  BindParentModal,
  Container,
  DataTable,
  PageTitle,
  RemoveParentModal,
  SummarySheetModal,
} from '../../components';
import { tableActions } from '../../store/reducers/tableReducer';
import {
  IDataSource,
  IInitialValues,
  IOrganization,
  IParentItem,
  IParentResponse,
  ISummaryTableItem,
} from './type';
import styles from './index.module.scss';
import { useFormik } from 'formik';
import { DamageNotificationModal } from '../../components';
import { INITIAL_VALUES } from './const';
import { useAppDispatch, useAppSelector } from '../../store';
import useHttpService from '../../services/hook';
import { getColumns } from './columns';
import validationSchema from './validations';
import { RequestMethods } from '../../services/enum';
import {
  cleanupAfterSubmit,
  prepareFetchParameters,
  prepareFormData,
  prepareModalData,
} from './helpers';
import { IPage, ISearch, ISort } from '../../store/reducers/tableReducer/type';
import { IExpandedRow } from '../../components/DataTable/type';
import { IEnum } from '../../services/type';
import { IResponse } from '../../types';
import { ColumnType } from 'antd/es/table';
import { DATE_FORMAT } from '../../assets/conts';

const { RangePicker } = DatePicker;

const DamageManagement: FC = () => {
  const dispatch = useAppDispatch();
  const executeService = useHttpService();

  // Redux State
  const search = useAppSelector((state) => state.tableReducer.search);
  const sort = useAppSelector((state) => state.tableReducer.sort);
  const page = useAppSelector((state) => state.tableReducer.page);
  const rows = useAppSelector((state) => state.tableReducer.rows);

  // State
  const [selectedItem, setSelectedItem] = useState<IDataSource | undefined>(
    undefined,
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [summaryTable, setSummaryTable] = useState<ISummaryTableItem[]>([]);
  const [isSummaryTableOpen, setIsSummaryTableOpen] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<IDataSource[]>([]);
  const [expandedRows, setExpandedRows] = useState<IExpandedRow[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [isOpenBindModal, setIsOpenBindModal] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<IDataSource | undefined>(
    undefined,
  );
  const [parentList, setParentList] = useState<IParentItem[]>([]);
  const [parentItem, setParentItem] = useState<IParentItem[]>([]);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [branches, setBranches] = useState<IEnum[]>([]);
  const [cities, setCities] = useState<IEnum[]>([]);
  const [countries, setCountries] = useState<IEnum[]>([]);
  const [currencies, setCurrencies] = useState<IEnum[]>([]);
  const [damageTypes, setDamageTypes] = useState<IEnum[]>([]);
  const [statusChoices, setStatusChoices] = useState<IEnum[]>([]);

  // Const
  const BIND_COLUMN: ColumnType<IDataSource> & { searchable?: boolean } = {
    title: '',
    key: 'select',
    dataIndex: 'select',
    render: (_, record) => (
      <Checkbox
        disabled={
          selectedRecord
            ? record.recent_status !== selectedRecord?.recent_status ||
              record.organization !== selectedRecord.organization
            : false
        }
        onClick={(e: MouseEvent<HTMLInputElement>) =>
          handleClickCheckbox(e, record)
        }
      />
    ),
  };

  // Handlers
  const handleFetchOrganizations = async () => {
    const response = await executeService<IResponse<IOrganization>>({
      method: RequestMethods.GET,
      url: `auth/organization/`,
    });
    if (response && 'results' in response) {
      setOrganizations(response.results);
    }
  };

  const handleFetchEnums = async () => {
    const response = await executeService<IEnum[]>({
      method: RequestMethods.GET,
      url: `enums/enums/get-damage-management-branches/`,
    });
    if (response && Array.isArray(response)) {
      setBranches(response);
    }

    const response2 = await executeService<IEnum[]>({
      method: RequestMethods.GET,
      url: `enums/enums/get-cities/`,
    });
    if (response2 && Array.isArray(response2)) {
      setCities(response2);
    }

    const response3 = await executeService<IEnum[]>({
      method: RequestMethods.GET,
      url: `enums/enums/get-countries/`,
    });
    if (response3 && Array.isArray(response3)) {
      setCountries(response3);
    }

    const response4 = await executeService<IEnum[]>({
      method: RequestMethods.GET,
      url: `enums/enums/get-currencies/`,
    });
    if (response4 && Array.isArray(response4)) {
      setCurrencies(response4);
    }

    const response5 = await executeService<IEnum[]>({
      method: RequestMethods.GET,
      url: `enums/enums/get-damage-management-damage-types/`,
    });
    if (response5 && Array.isArray(response5)) {
      setDamageTypes(response5);
    }

    const response6 = await executeService<IEnum[]>({
      method: RequestMethods.GET,
      url: `enums/enums/get-damage-management-status-choices/`,
    });
    if (response6 && Array.isArray(response6)) {
      setStatusChoices(response6);
    }
  };

  const handleFetchData = async (
    dates?: string[],
    search?: ISearch[],
    sort?: ISort,
    page?: IPage,
  ) => {
    const parameters = prepareFetchParameters(dates, search, sort, page);
    const response = await executeService<IResponse<IDataSource>>({
      method: RequestMethods.GET,
      url: `damage-management/damage-management/${parameters}`,
    });
    if (response && 'results' in response) {
      dispatch(tableActions.changeTableTotal(response.count));
      const results = response.results.reduce<IDataSource[]>(
        (prev: IDataSource[], cur: IDataSource) => {
          return [...prev, { ...cur, key: cur.id }];
        },
        [],
      );
      setDataSource(results);
    }
  };

  const handleClickEye = async (id: number) => {
    dispatch(tableActions.changeTableRow(id));
    const expandedRows = await executeService<IExpandedRow[]>({
      method: RequestMethods.GET,
      url: `damage-management/damage-management/${id}/get-latest-processes/`,
    });
    expandedRows &&
      Array.isArray(expandedRows) &&
      setExpandedRows(expandedRows);
  };

  const handleOpenSummaryTable = async () => setIsSummaryTableOpen(true);

  const handleChangeSummaryTable = async (id: number) => {
    const summaryTable = await executeService<ISummaryTableItem[]>({
      method: RequestMethods.GET,
      url: `damage-management/summary-table/${id}/get-summary-table/`,
    });
    Array.isArray(summaryTable) && setSummaryTable(summaryTable);
  };

  const handleSaveSummaryTable = () => {
    setIsSummaryTableOpen(false);
    setSummaryTable([]);
  };

  const handleCancelSummaryTable = () => {
    setIsSummaryTableOpen(false);
    setSummaryTable([]);
  };

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    formik.setErrors({});
    formik.setTouched({});
  };

  const handleOpenEditModal = async (id: number) => {
    const selectedItem = await executeService<IDataSource>({
      method: RequestMethods.GET,
      url: `damage-management/damage-management/${id}/`,
    });
    selectedItem && 'id' in selectedItem && setSelectedItem(selectedItem);
  };

  const handleCloseEditModal = () => {
    setSelectedItem(undefined);
    formik.setErrors({});
    formik.setTouched({});
  };

  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) =>
    formik.setFieldValue('upload_files', e.target.files);

  const handleClickCancel = async (id: number) => {
    try {
      const formData = new FormData();
      formData.append('id', id.toString());
      formData.append('status', 'cancel_expected');
      await executeService<unknown>({
        method: RequestMethods.POST,
        url: `damage-management/damage-management/${id}/update-status/`,
        data: formData,
        contentType: 'multipart/form-data',
      });
    } catch (e) {
      console.log(e);
    } finally {
      cleanupAfterSubmit(
        setIsModalOpen,
        setSelectedItem,
        formik.resetForm,
        handleFetchData,
      );
    }
  };

  const handleClickDelete = async (id: number) => {
    try {
      await executeService<unknown>({
        method: RequestMethods.DELETE,
        url: `damage-management/damage-management/${id}/`,
      });
    } catch (e) {
      console.log(e);
    } finally {
      cleanupAfterSubmit(
        setIsModalOpen,
        setSelectedItem,
        formik.resetForm,
        handleFetchData,
      );
    }
  };

  const handleClickBindDamage = async () => setIsOpenBindModal((prev) => !prev);

  const handleClickCheckbox = (
    e: MouseEvent<HTMLInputElement>,
    record: IDataSource,
  ) => {
    const target = e.target as HTMLInputElement;
    if (!rows.length && target.checked) setSelectedRecord(record);
    else if (rows.length === 1 && !target.checked) setSelectedRecord(undefined);
    dispatch(
      tableActions.changeTableRows({
        id: record.id,
        propertyKey: 'select',
        propertyValue: target.checked,
      }),
    );
  };

  const handleClickBindSave = async () => {
    try {
      await executeService<IDataSource>({
        method: RequestMethods.POST,
        url: 'damage-management/parent/',
        data: {
          damage_managements: rows.map((row) => row.id),
          organization: selectedRecord?.organization,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(tableActions.clearTableRows());
      dispatch(tableActions.changeTablePage({ number: 0, size: 10 }));
      handleFetchData();
      setIsOpenBindModal(false);
      setSelectedRecord(undefined);
    }
  };

  const handleClickBindCancel = () => {
    dispatch(tableActions.clearTableRows());
    setIsOpenBindModal(false);
    setSelectedRecord(undefined);
  };

  const handleClickRemoveParent = async (parent: number, id: number) => {
    const response = await executeService<IParentResponse>({
      method: RequestMethods.GET,
      url: `damage-management/parent/${parent}/`,
    });

    if (response && 'id' in response) {
      setParentList(response.damage_managements.filter((dm) => dm.id !== id));
      setParentItem(response.damage_managements.filter((dm) => dm.id === id));
    }
  };

  const handleClickRemoveCancel = () => {
    setParentItem([]);
    setParentList([]);
  };

  const handleClickRemoveSave = async () => {
    const removedItem = dataSource.find((item) => item.id === parentItem[0].id);
    await executeService<unknown>({
      method: RequestMethods.PATCH,
      url: `damage-management/damage-management/${parentItem[0].id}/`,
      data: { ...removedItem, parent: null },
    });
    setParentItem([]);
    setParentList([]);
    handleFetchData();
  };

  const handleSubmit = async (values: IInitialValues) => {
    try {
      const formData = prepareFormData(values);

      if (selectedItem) {
        const statusFormData = new FormData();
        statusFormData.append('status', values.recent_status);
        await executeService<IDataSource>({
          method: RequestMethods.PUT,
          url: `damage-management/damage-management/${selectedItem.id}/`,
          data: formData,
          contentType: 'multipart/form-data',
        });
        await executeService<IDataSource>({
          method: RequestMethods.POST,
          url: `damage-management/damage-management/${selectedItem.id}/update-status/`,
          data: statusFormData,
          contentType: 'multipart/form-data',
        });
      } else {
        await executeService<IDataSource>({
          method: RequestMethods.POST,
          url: 'damage-management/damage-management/',
          data: formData,
          contentType: 'multipart/form-data',
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      cleanupAfterSubmit(
        setIsModalOpen,
        setSelectedItem,
        formik.resetForm,
        handleFetchData,
      );
      dispatch(tableActions.changeTablePage({ number: 0, size: 20 }));
    }
  };

  // Columns
  const columns = getColumns(
    handleClickEye,
    handleClickDelete,
    handleClickCancel,
    handleOpenEditModal,
    handleClickRemoveParent,
    organizations,
    branches,
    cities,
    countries,
    currencies,
    damageTypes,
    statusChoices,
  );

  // Form Management
  const formik = useFormik<IInitialValues>({
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit: handleSubmit,
  });

  //Effects
  useEffect(() => {
    handleFetchOrganizations();
    handleFetchEnums();
  }, []);

  useEffect(() => {
    if (selectedItem) {
      const result = prepareModalData(selectedItem);
      result && formik.setValues(result);
    } else {
      formik.setValues(INITIAL_VALUES);
    }
  }, [selectedItem]);

  useEffect(() => {
    handleFetchData(dates, search, sort, page);
  }, [dates, search, sort.key, sort.order, page.number, page.size]);

  // JSX Return
  return (
    <Container>
      <PageTitle title="Hasar Yönetimi" />
      <Flex vertical gap="middle">
        <Flex justify="space-between">
          <RangePicker
            format={DATE_FORMAT}
            placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
            className={styles.datePicker}
            onChange={(_, dates: [string, string]) => setDates(dates)}
          />
          <Flex gap={5}>
            <AuthorizedButton
              model="parentdamagemanagement"
              action="add"
              className={styles.bindButton}
              onClick={handleClickBindDamage}
            >
              <div className={styles.buttonText}>Dosya Bağlama</div>
            </AuthorizedButton>
            <Button
              className={styles.displayButton}
              onClick={handleOpenSummaryTable}
            >
              <div className={styles.buttonText}>İcmal Tablosunu Görüntüle</div>
            </Button>
            <AuthorizedButton
              model="damagemanagement"
              action="add"
              className={styles.addButton}
              onClick={handleOpenModal}
            >
              <PlusOutlined className={styles.buttonIcon} />
              <div className={styles.buttonText}>Yeni Hasar Bildirimi</div>
            </AuthorizedButton>
          </Flex>
        </Flex>
        <DataTable<IDataSource>
          columns={!isOpenBindModal ? [BIND_COLUMN, ...columns] : columns}
          dataSource={dataSource}
          scrollable
          expandedRows={expandedRows}
          statusChoices={statusChoices}
        />
      </Flex>
      <SummarySheetModal
        open={isSummaryTableOpen}
        title="İcmal Tablosu"
        subTitle="İcmal Tablosu"
        organizations={organizations}
        summaryTable={summaryTable}
        handleChangeSummaryTable={handleChangeSummaryTable}
        handleSubmit={handleSaveSummaryTable}
        handleCancel={handleCancelSummaryTable}
        statusChoices={statusChoices}
      />
      <DamageNotificationModal
        type="CREATE"
        open={isModalOpen}
        title="Yeni Hasar Bildirimi Oluşturma"
        subTitle="Yeni Hasar Bildirimi Oluşturma"
        formik={formik}
        organizations={organizations}
        handleUploadFile={handleUploadFile}
        handleSubmit={formik.handleSubmit}
        handleCancel={handleCloseModal}
        branches={branches}
        cities={cities}
        countries={countries}
        currencies={currencies}
        damageTypes={damageTypes}
        statusChoices={statusChoices}
      />
      <DamageNotificationModal
        type="EDIT"
        open={!!selectedItem}
        title="Hasar Bildirimi Güncelleme"
        subTitle="Hasar Bildirimi Güncelleme"
        formik={formik}
        organizations={organizations}
        handleUploadFile={handleUploadFile}
        handleSubmit={formik.handleSubmit}
        handleCancel={handleCloseEditModal}
        branches={branches}
        cities={cities}
        countries={countries}
        currencies={currencies}
        damageTypes={damageTypes}
        statusChoices={statusChoices}
      />
      <BindParentModal
        open={isOpenBindModal}
        title="Dosya Bağlama"
        subTitle="Dosya Bağlama"
        dataSource={dataSource.filter((ds) =>
          rows.map((row) => row.id).includes(ds.id),
        )}
        handleCancel={handleClickBindCancel}
        handleSubmit={handleClickBindSave}
        branches={branches}
        cities={cities}
        countries={countries}
        damageTypes={damageTypes}
        statusChoices={statusChoices}
        organizations={organizations}
      />
      <RemoveParentModal
        open={!!parentItem.length && !!parentList.length}
        title="Bağlı Dosya Ayırma"
        subTitle="Bağlı Dosya Ayırma"
        item={parentItem}
        list={parentList}
        handleCancel={handleClickRemoveCancel}
        handleSubmit={handleClickRemoveSave}
        branches={branches}
        cities={cities}
        countries={countries}
        damageTypes={damageTypes}
        statusChoices={statusChoices}
        organizations={organizations}
      />
    </Container>
  );
};

export default DamageManagement;
