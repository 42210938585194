import * as Yup from 'yup';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../../assets/conts';

const validationSchema = Yup.object().shape({
  organization: Yup.number().notOneOf([0], 'Firma zorunludur.'),
  type: Yup.string(),
  policy_number: Yup.number().typeError('Nümerik değer olmalıdır'),
  branch: Yup.string().required('Branş zorunludur.'),
  start_date: Yup.string()
    .required('Başlangıç tarihi zorunludur.')
    .test(
      'start_date-before-end_date',
      'Başlangıç tarihi bitiş tarihinden sonra olamaz.',
      function (value) {
        const { end_date } = this.parent;
        if (!value || !end_date) return true;

        const startDate = dayjs(value, DATE_FORMAT, true);
        const endDate = dayjs(end_date, DATE_FORMAT, true);

        if (!startDate.isValid() || !endDate.isValid()) {
          console.error('Geçersiz tarih formatı');
          return false;
        }

        return startDate.isBefore(endDate) || startDate.isSame(endDate);
      },
    ),
  end_date: Yup.string()
    .required('Bitiş tarihi zorunludur.')
    .test(
      'end_date-after-start_date',
      'Bitiş tarihi başlangıç tarihinden önce olamaz.',
      function (value) {
        const { start_date } = this.parent;
        if (!value || !start_date) return true;

        const endDate = dayjs(value, DATE_FORMAT, true);
        const startDate = dayjs(start_date, DATE_FORMAT, true);

        if (!endDate.isValid() || !startDate.isValid()) {
          console.error('Geçersiz tarih formatı');
          return false;
        }

        return endDate.isAfter(startDate) || endDate.isSame(startDate);
      },
    ),
});

export default validationSchema;
