import { IEnum } from '../services/type';

export const getValueFromEnum = (
  enums: IEnum[],
  key: string,
): string | undefined => {
  return enums.find((obj) => obj.key === key)?.value || '';
};

export const formatISOString = (text: string) => {
  return text.split('T')[0] + ' ' + text.split('T')[1].split('.')[0];
};
