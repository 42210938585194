import { IDataSource, IOrganization } from './type';
import { Flex, Popconfirm, Tag, Tooltip } from 'antd';
import { AuthorizedIcon, Icon } from '../../../components';
import TrashSolidBlack from '../../../assets/icons/trash-solid-b-black.svg';
import PenToSquareSolidBlack from '../../../assets/icons/pen-to-square-solid-w-black.svg';
//import FilePdfSolidBlack from '../../../assets/icons/file-pdf-solid-black.svg';
import EyeRegularWBlack from '../../../assets/icons/eye-regular-w-black.svg';
import Variant99Black from '../../../assets/icons/variant99-black.svg';
import styles from './index.module.scss';
import { status_colors } from '../../../assets/conts';
//import { downloadFile } from '../../../helpers/File';
import { IEnum } from '../../../services/type';
import { getValueFromEnum } from '../../../utils/functions';
import { ColumnType } from 'antd/es/table';

export const getColumns = (
  handleClickEye: (id: number) => void,
  handleOpenEditModal: (id: number) => void,
  handleClickDelete: (id: number) => Promise<void>,
  handleClickCancel: (id: number) => Promise<void>,
  organizations: IOrganization[],
  currencies: IEnum[],
  statusChoices: IEnum[],
  transportationTypes: IEnum[],
): Array<ColumnType<IDataSource> & { searchable?: boolean }> => [
  {
    title: 'Firma',
    dataIndex: 'organization',
    key: 'organization',
    searchable: true,
    sorter: (a, b) => a.organization - b.organization,
    render: (_, record) => (
      <div>
        {organizations.find((item) => item.id === record.organization)?.name}
      </div>
    ),
  },
  {
    title: 'Poliçe No',
    dataIndex: 'policy_number',
    key: 'policy_number',
    width: 125,
    sorter: (a, b) => a.policy_number.localeCompare(b.policy_number),
  },
  {
    title: 'Sevk Tipi',
    dataIndex: 'transportation_type',
    key: 'transportation_type',
    width: 125,
    filters: transportationTypes.map((item) => ({
      text: getValueFromEnum(transportationTypes, item.key) || '',
      value: item.key,
    })),
    filterMode: 'tree',
    filterSearch: true,
    sorter: (a, b) =>
      a.transportation_type.localeCompare(b.transportation_type),
    render: (_: unknown, record: IDataSource) => (
      <Tag className={styles[`${record.transportation_type}Tag`]}>
        {getValueFromEnum(transportationTypes, record.transportation_type)}
      </Tag>
    ),
  },
  {
    title: 'Sevk Tarihi',
    dataIndex: 'expedition_date',
    key: 'expedition_date',
    width: 125,
    sorter: (a, b) => a.expedition_date.localeCompare(b.expedition_date),
  },
  {
    title: 'Emtia Bedeli',
    dataIndex: 'insurance_value',
    key: 'insurance_value',
    width: 150,
    sorter: (a, b) => (a.insurance_value || 0) - (b.insurance_value || 0),
    render: (_: unknown, record: IDataSource) => (
      <div>
        {record.insurance_value
          ? `${record.insurance_value} ${getValueFromEnum(currencies, record.insurance_value_unit)}`
          : ''}
      </div>
    ),
  },
  {
    title: 'Poliçe Primi',
    dataIndex: 'policy_value',
    key: 'policy_value',
    width: 125,
    sorter: (a, b) => (a.policy_value || 0) - (b.policy_value || 0),
    render: (_: unknown, record: IDataSource) => (
      <div>
        {record.policy_value
          ? `${record.policy_value} ${getValueFromEnum(currencies, record.policy_value_unit)}`
          : ''}
      </div>
    ),
  },
  {
    title: 'Sertifika Durumu',
    dataIndex: 'recent_status',
    key: 'recent_status',
    width: 175,
    filters: statusChoices.map((item) => ({
      text: getValueFromEnum(statusChoices, item.key) || '',
      value: item.key,
    })),
    filterMode: 'tree',
    filterSearch: true,
    render: (_: unknown, record: IDataSource) => (
      <Tag color={status_colors[record.recent_status]}>
        {getValueFromEnum(statusChoices, record.recent_status)}
      </Tag>
    ),
  },
  {
    title: 'Açıklama',
    dataIndex: 'description',
    key: 'description',
    width: 150,
    sorter: (a, b) => a.description.localeCompare(b.description),
    render: (_: unknown, record: IDataSource) => (
      <Tooltip title={record.description}>
        <span>{`${record.description.slice(0, 20)}...`}</span>
      </Tooltip>
    ),
  },
  /* {
    title: 'Ek Dosya',
    dataIndex: 'additional_files',
    key: 'additional_files',
    render: (_: unknown, record: IDataSource) => (
      <Flex gap={14}>
        {record.additional_files
          .reverse()
          .slice(0, 2)
          .map((item) => (
            <Popconfirm
              key={record.id}
              title={
                <div className={styles.popconfirmTitle}>
                  Ek dosyayı indirmek istiyor musunuz?
                </div>
              }
              overlayInnerStyle={{
                width: 150,
                borderColor: '#E59500',
                borderWidth: 1,
                borderStyle: 'solid',
              }}
              okText="İndir"
              cancelText="İptal"
              icon={null}
              color="#FAFAFA"
              okButtonProps={{
                style: { backgroundColor: '#E59500', width: '45%' },
              }}
              cancelButtonProps={{
                style: {
                  color: '#E59500',
                  borderColor: '#E59500',
                  width: '45%',
                },
              }}
              onConfirm={() => downloadFile(item.file)}
            >
              <Icon source={FilePdfSolidBlack} />
            </Popconfirm>
          ))}
      </Flex>
    ),
  }, */
  {
    title: 'İşlem',
    dataIndex: 'action',
    key: 'action',
    render: (_: unknown, record: IDataSource) => (
      <Flex justify="flex-end" gap={14}>
        <Icon
          source={EyeRegularWBlack}
          onClick={() => record?.id && handleClickEye(record.id)}
        />
        <Popconfirm
          title={
            <div className={styles.popconfirmTitle}>
              İptal işlemini onaylıyor musunuz?
            </div>
          }
          overlayInnerStyle={{
            width: 150,
            borderColor: '#D6222A',
            borderWidth: 1,
            borderStyle: 'solid',
          }}
          okText="Onayla"
          cancelText="İptal"
          icon={null}
          color="#FAFAFA"
          okButtonProps={{ danger: true, style: { width: '45%' } }}
          cancelButtonProps={{ danger: true, style: { width: '45%' } }}
          onConfirm={() => record.id && handleClickCancel(record.id)}
        >
          <Icon source={Variant99Black} />
        </Popconfirm>
        <Popconfirm
          title={
            <div className={styles.popconfirmTitle}>
              Silme işlemini onaylıyor musunuz?
            </div>
          }
          overlayInnerStyle={{
            width: 150,
            borderColor: '#D6222A',
            borderWidth: 1,
            borderStyle: 'solid',
          }}
          okText="Onayla"
          cancelText="İptal"
          icon={null}
          color="#FAFAFA"
          okButtonProps={{ danger: true, style: { width: '45%' } }}
          cancelButtonProps={{ danger: true, style: { width: '45%' } }}
          onConfirm={() => record.id && handleClickDelete(record.id)}
        >
          <AuthorizedIcon
            action="delete"
            model="expeditioncertificate"
            source={TrashSolidBlack}
          />
        </Popconfirm>
        <AuthorizedIcon
          action="change"
          model="expeditioncertificate"
          source={PenToSquareSolidBlack}
          onClick={() => record?.id && handleOpenEditModal(record.id)}
        />
      </Flex>
    ),
  },
];
