import { Tag } from 'antd';
import { IDataSource } from './type';
import styles from './index.module.scss';
import { IEnum } from '../../services/type';
import { getValueFromEnum } from '../../utils/functions';
import { IOrganization } from '../../pages/DamageManagement/type';
import { ColumnType } from 'antd/es/table';

export const getColumns = (
  branches: IEnum[],
  cities: IEnum[],
  countries: IEnum[],
  damageTypes: IEnum[],
  organizations: IOrganization[],
): Array<ColumnType<IDataSource> & { searchable?: boolean }> => [
  {
    title: 'Firma',
    dataIndex: 'organization',
    key: 'organization',
    render: (_, record) => (
      <div>
        {organizations.find((item) => item.id === record.organization)?.name}
      </div>
    ),
  },
  {
    title: 'Dosya No',
    dataIndex: 'policy_number',
    key: 'policy_number',
  },
  {
    title: 'Bağlı Dosya',
    dataIndex: 'parent',
    key: 'parent',
  },
  {
    title: 'Hasar Tipi',
    dataIndex: 'damage_type',
    key: 'damage_type',
    render: (_: unknown, record: IDataSource) => (
      <Tag className={styles[`${record.damage_type}Tag`]}>
        {getValueFromEnum(damageTypes, record.damage_type)}
      </Tag>
    ),
  },
  {
    title: 'Branş',
    dataIndex: 'branch',
    key: 'branch',
    render: (_: unknown, record: IDataSource) => (
      <div>{getValueFromEnum(branches, record.branch)}</div>
    ),
  },
  {
    title: 'Hasar Yeri',
    dataIndex: 'country',
    key: 'country',
    render: (_, record: IDataSource) => (
      <div>{`${record.country ? getValueFromEnum(countries, record.country) : ''}${record.city ? ` / ${getValueFromEnum(cities, record.city)}` : ''} `}</div>
    ),
  },
];
