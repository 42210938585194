import { FC, PropsWithChildren } from 'react';
import { Flex } from 'antd';
import styles from './index.module.scss';
import { useAppSelector } from '../../store';

const Container: FC<PropsWithChildren> = ({ children }) => {
  const isSideMenuCollapsed = useAppSelector(
    (state) => state.layoutReducer.isSideMenuCollapsed,
  );

  return (
    <Flex
      vertical
      gap={40}
      className={
        styles[`container-${isSideMenuCollapsed ? 'collapsed' : 'expanded'}`]
      }
    >
      {children}
    </Flex>
  );
};

export default Container;
