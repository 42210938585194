import { createSlice } from '@reduxjs/toolkit';
import { ILayoutInitialStateType, IAction } from './type';

const initialState: ILayoutInitialStateType = {
  isSideMenuCollapsed: false,
};

const setSideMenuCollapse = (
  state: ILayoutInitialStateType,
  action: IAction<boolean>,
) => {
  state.isSideMenuCollapsed = action.payload;
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: { setSideMenuCollapse },
});

export const layoutActions = layoutSlice.actions;
const layoutReducer = layoutSlice.reducer;
export default layoutReducer;
