import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Checkbox, DatePicker, Flex, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  AuthorizedButton,
  Container,
  DataTable,
  PageTitle,
  PolicyModal,
} from '../../../components';
import { IDataSource, IInitialValues, IOrganization } from './type';
import { INITIAL_VALUES } from './const';
import styles from './index.module.scss';
import { RequestMethods } from '../../../services/enum';
import UseHttpService from '../../../services/hook';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  IPage,
  ISearch,
  ISort,
} from '../../../store/reducers/tableReducer/type';
import validationSchema from './validations';
import {
  cleanupAfterSubmit,
  prepareFetchParameters,
  prepareFormData,
  setFormForSelectedPolicy,
} from './helpers';
import { getColumns } from './columns';
import { tableActions } from '../../../store/reducers/tableReducer';
import { IEnum } from '../../../services/type';
import { IResponse } from '../../../types';
import { DATE_FORMAT } from '../../../assets/conts';

const { RangePicker } = DatePicker;

const Policy: FC = () => {
  const search = useAppSelector((state) => state.tableReducer.search);
  const sort = useAppSelector((state) => state.tableReducer.sort);
  const page = useAppSelector((state) => state.tableReducer.page);
  const dispatch = useAppDispatch();
  const executeService = UseHttpService();

  // State
  const [selectedPolicy, setSelectedPolicy] = useState<IDataSource | undefined>(
    undefined,
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<IDataSource[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [policyTypes, setPolicyTypes] = useState<IEnum[]>([]);

  // Handlers
  const handleFetchOrganizations = async () => {
    const response = await executeService<IResponse<IOrganization>>({
      method: RequestMethods.GET,
      url: `auth/organization/`,
    });
    if (response && 'results' in response) {
      setOrganizations(response.results);
    }
  };

  const handleFetchEnums = async () => {
    const response = await executeService<IEnum[]>({
      method: RequestMethods.GET,
      url: `enums/enums/get-policy-types/`,
    });
    if (response && Array.isArray(response)) {
      setPolicyTypes(response);
    }
  };

  const handleFetchData = async (
    isExpired?: boolean,
    dates?: string[],
    search?: ISearch[],
    sort?: ISort,
    page?: IPage,
  ) => {
    const parameters = prepareFetchParameters(
      isExpired,
      dates,
      search,
      sort,
      page,
    );
    const response = await executeService<IResponse<IDataSource>>({
      method: RequestMethods.GET,
      url: `transportation-policies/policies/${parameters}`,
    });
    if (response && 'results' in response) {
      dispatch(tableActions.changeTableTotal(response.count));
      setDataSource(response.results);
    }
  };

  const handleChangeExpired = () => setIsExpired((prev) => !prev);

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    formik.setErrors({});
    formik.setTouched({});
  };

  const handleOpenEditModal = async (id: number) => {
    const selectedPolicy = await executeService<IDataSource>({
      method: RequestMethods.GET,
      url: `transportation-policies/policies/${id}/`,
    });
    selectedPolicy &&
      'id' in selectedPolicy &&
      setSelectedPolicy(selectedPolicy);
  };

  const handleCloseEditModal = () => {
    setSelectedPolicy(undefined);
    formik.setErrors({});
    formik.setTouched({});
  };

  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      formik.setFieldValue('upload_files', e.target.files[0]);
    }
  };

  const handleClickDelete = async (id: number) => {
    try {
      await executeService<unknown>({
        method: RequestMethods.DELETE,
        url: `transportation-policies/policies/${id}/`,
      });
    } catch (e) {
      console.log(e);
    } finally {
      cleanupAfterSubmit(
        setIsModalOpen,
        setSelectedPolicy,
        formik.resetForm,
        handleFetchData,
      );
    }
  };

  const handleSubmit = async (values: IInitialValues) => {
    try {
      const formData = prepareFormData(values);
      if (selectedPolicy) {
        await executeService<IDataSource>({
          method: RequestMethods.PUT,
          url: `transportation-policies/policies/${selectedPolicy.id}/`,
          data: formData,
          contentType: 'multipart/form-data',
        });
      } else {
        await executeService<IDataSource>({
          method: RequestMethods.POST,
          url: 'transportation-policies/policies/',
          data: formData,
          contentType: 'multipart/form-data',
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      cleanupAfterSubmit(
        setIsModalOpen,
        setSelectedPolicy,
        formik.resetForm,
        handleFetchData,
      );
      dispatch(tableActions.changeTablePage({ number: 0, size: 20 }));
    }
  };

  // Columns
  const columns = getColumns(
    handleClickDelete,
    handleOpenEditModal,
    organizations,
    policyTypes,
  );

  // Form Management
  const formik = useFormik<IInitialValues>({
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit: handleSubmit,
  });

  // Effects
  useEffect(() => {
    handleFetchOrganizations();
    handleFetchEnums();
  }, []);

  useEffect(() => {
    if (selectedPolicy) {
      const values = setFormForSelectedPolicy(selectedPolicy);
      formik.setValues(values);
    } else {
      formik.setValues(INITIAL_VALUES);
    }
  }, [selectedPolicy]);

  useEffect(() => {
    handleFetchData(isExpired, dates, search, sort, page);
  }, [isExpired, dates, search, sort.key, sort.order, page.number, page.size]);

  // JSX Return
  return (
    <Container>
      <PageTitle title="Nakliyat Poliçeler" />
      <Flex vertical gap="middle">
        <Flex justify="space-between">
          <Flex vertical gap="small">
            <RangePicker
              format={DATE_FORMAT}
              placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
              className={styles.datePicker}
              onChange={(_, dates: [string, string]) => setDates(dates)}
            />
            <Flex gap="small">
              <Checkbox checked={isExpired} onChange={handleChangeExpired} />
              <Typography className={styles.outOfDateText}>
                Sadece Aktif Poliçeleri Göster
              </Typography>
            </Flex>
          </Flex>
          <AuthorizedButton
            model="transportationpolicy"
            action="add"
            className={styles.button}
            onClick={handleOpenModal}
          >
            <PlusOutlined className={styles.buttonIcon} />
            <div className={styles.buttonText}>Yeni Poliçe Talebi</div>
          </AuthorizedButton>
        </Flex>
        <DataTable<IDataSource> columns={columns} dataSource={dataSource} />
      </Flex>
      <PolicyModal
        type="CREATE"
        open={isModalOpen}
        title="Yeni Nakliyat Poliçesi Oluşturma"
        subTitle="Yeni Nakliyat Poliçesi Oluşturma"
        formik={formik}
        organizations={organizations}
        handleUploadFile={handleUploadFile}
        handleSubmit={formik.handleSubmit}
        handleCancel={handleCloseModal}
        policyTypes={policyTypes}
      />
      <PolicyModal
        type="EDIT"
        open={!!selectedPolicy}
        title="Nakliyat Poliçesi Güncelleme"
        formik={formik}
        organizations={organizations}
        handleUploadFile={handleUploadFile}
        handleSubmit={formik.handleSubmit}
        handleCancel={handleCloseEditModal}
        policyTypes={policyTypes}
      />
    </Container>
  );
};

export default Policy;
