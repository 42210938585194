export const DATE_FORMAT = 'DD-MM-YYYY';

export const status_colors: { [key: string]: string } = {
  issued: '#87A330',
  cancelled: '#D6222A',
  request_created: '#E59500',
  checkout: '#002A4D99',
  report: '#002A4D99',
  updated: '#002A4D',
  documents: '#E5950099',
  cancel_expected: '#D6222A99',
};

export const deactivation_period_months: { [key: string]: string } = {
  '1': '1 Ay',
  '3': '3 Ay',
  '6': '6 Ay',
  '12': '12 Ay',
  '-1': 'Süresiz',
};

export const deactivation_period_months_options = [
  { value: 1, label: '1 Ay' },
  { value: 3, label: '3 Ay' },
  { value: 6, label: '6 Ay' },
  { value: 12, label: '12 Ay' },
  { value: -1, label: 'Süresiz' },
];
