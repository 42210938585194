import { ItemType, MenuItemType } from 'antd/es/menu/interface';
import HouseSolid from '../../assets/icons/house-solid-w.svg';
import TruckSolid from '../../assets/icons/truck-solid.svg';
import CarSolid from '../../assets/icons/car-solid.svg';
import NotesMedicalSolid from '../../assets/icons/notes-medical-solid.svg';
import CarBurstSolid from '../../assets/icons/car-burst-solid.svg';
//import CalculatorSolid from "../../assets/icons/calculator-solid.svg";
import SitemapSolidB from '../../assets/icons/sitemap-solid-b.svg';
import UserGearSolid from '../../assets/icons/user-gear-solid.svg';
import { IPermission } from '../../store/reducers/userReducer/type';

export const getItems = (
  permissions: IPermission[],
): ItemType<MenuItemType>[] => {
  return [
    {
      key: '/',
      icon: <img src={HouseSolid} alt="HouseSolid" />,
      label: 'Ana Sayfa',
    },
    {
      key: '10',
      label: 'Ana Poliçeler',
      icon: <img src={SitemapSolidB} alt="SitemapSolidB" />,
      children: [
        permissions.some(
          (permission) => permission.model === 'domesticinsurance',
        )
          ? {
              key: '/main-insurances/domestic-insurance',
              label: 'Yurtiçi Poliçeler',
            }
          : null,
        permissions.some((permission) => permission.model === 'abroadinsurance')
          ? {
              key: '/main-insurances/abroad-insurance',
              label: 'Yurtdışı Poliçeler',
            }
          : null,
        permissions.some((permission) => permission.model === 'otherinsurance')
          ? {
              key: '/main-insurances/other-insurance',
              label: 'Diğer Poliçeler',
            }
          : null,
      ],
    },
    permissions.some((permission) => permission.model === 'motorinsurance')
      ? {
          key: '/motor-insurance',
          icon: <img src={CarSolid} alt="CarSolid" />,
          label: 'Oto',
        }
      : null,
    {
      key: 'Nakliyat Sigortaları',
      icon: <img src={TruckSolid} alt="TruckSolid" />,
      label: 'Nakliyat',
      children: [
        permissions.some(
          (permission) => permission.model === 'transportationpolicy',
        )
          ? {
              key: '/freight-insurance/policy',
              label: 'Nakliyat Poliçeleri',
            }
          : null,
        permissions.some(
          (permission) => permission.model === 'expeditioncertificate',
        )
          ? {
              key: '/freight-insurance/expedition-certificate',
              label: 'Abonman Sefer Sertifikaları',
            }
          : null,
      ],
    },
    {
      key: 'Sağlık',
      label: 'Sağlık',
      icon: <img src={NotesMedicalSolid} alt="NotesMedicalSolid" />,
      children: [
        {
          key: 'Grup Sağlık',
          label: 'Grup Sağlık',
          children: [
            {
              key: '/group-health-insurance/policy',
              label: 'Poliçeler',
            },
            permissions.some(
              (permission) =>
                permission.model === 'grouphealthinsuranceindividuals',
            )
              ? {
                  key: '/group-health-insurance/individuals',
                  label: 'Sigortalılar',
                }
              : null,
          ],
        },
        {
          key: '/seyahat-saglik',
          label: 'Seyahat Sağlık',
        },
      ],
    },
    permissions.some((permission) => permission.model === 'damagemanagement')
      ? {
          key: '/damage-management',
          label: 'Hasar',
          icon: <img src={CarBurstSolid} alt="CarBurstSolid" />,
        }
      : null,
    /*  {
            key: "12",
            label: "Muhasebe",
            icon: <img src={CalculatorSolid} alt="CalculatorSolid" />
        }, */
    {
      key: 'Ayarlar',
      label: 'Ayarlar',
      icon: <img src={UserGearSolid} alt="UserGearSolid" />,
      children: [
        permissions.some((permission) => permission.model === 'organization')
          ? {
              key: '/user-profiles-and-authorisation/organization-settings',
              label: 'Firmalar',
            }
          : null,
        permissions.some((permission) => permission.model === 'user')
          ? {
              key: '/user-profiles-and-authorisation/user-settings',
              label: 'Kullanıcılar',
            }
          : null,
        permissions.some((permission) => permission.model === 'extendedgroup')
          ? {
              key: '/user-profiles-and-authorisation/profile-settings',
              label: 'Profiller',
            }
          : null,
      ],
    },
  ];
};

export const getRootSubmenuKeys = () => {
  return getItems([]).reduce<string[]>((acc, item: ItemType<MenuItemType>) => {
    if (item && 'children' in item) {
      if (item.children) {
        acc.push(item.key as string);
      }
    }
    return acc;
  }, []);
};
