import { FC, useRef } from 'react';
import { DatePicker, Flex, Input, Select } from 'antd';
import {
  ErrorMessage,
  LabelTitle,
  MailCheckbox,
  ModalDialog,
  StatusDropdown,
  UploadButton,
} from '..';
import { IProps } from './type';
import styles from '../../assets/styles/index.module.scss';
import dayjs from 'dayjs';
import { CheckSibaOrganization } from '../../helpers/Organization';
import { useAppSelector } from '../../store';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DATE_FORMAT } from '../../assets/conts';

dayjs.extend(customParseFormat);

const OtherInsuranceModal: FC<IProps> = (props) => {
  const { getFieldProps, setFieldValue, values, errors, touched } =
    props.formik;
  const uploadInput = useRef<HTMLInputElement | null>(null);
  const { organization } = useAppSelector(
    (state) => state.userReducer.userInfo,
  );

  return (
    <ModalDialog
      title={props.title}
      subTitle={props.subTitle}
      open={props.open}
      handleOk={props.handleSubmit}
      handleCancel={props.handleCancel}
    >
      <Flex gap="middle" vertical>
        <Flex align="flex-start" justify="space-between">
          <LabelTitle>Firma Bilgileri</LabelTitle>
          <Flex vertical gap="small">
            <Select
              id="organization"
              value={
                props.organizations.find(
                  (item) => item.id === (values.organization || organization),
                )?.name || undefined
              }
              placeholder="Firma Seçiniz"
              style={{ width: 200 }}
              disabled={!CheckSibaOrganization()}
              options={props.organizations.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) => setFieldValue('organization', value)}
            />
            {touched.organization && errors.organization && (
              <ErrorMessage>{errors.organization}</ErrorMessage>
            )}
          </Flex>
        </Flex>
        <Flex gap="small" vertical>
          <LabelTitle>Poliçe Bilgileri</LabelTitle>
          <Flex justify="space-between" gap="small">
            <Flex vertical gap="small" className={styles.widthHalf}>
              <Input
                placeholder="Poliçe No"
                {...getFieldProps('policy_number')}
              />
              {touched.policy_number && errors.policy_number && (
                <ErrorMessage>{errors.policy_number}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={styles.widthHalf}>
              <Select
                id="branch"
                value={values.branch || undefined}
                placeholder="Branş"
                options={props.branches.map((b) => ({
                  value: b.key,
                  label: b.value,
                }))}
                onChange={(value) => setFieldValue('branch', value)}
              />
              {touched.branch && errors.branch && (
                <ErrorMessage>{errors.branch}</ErrorMessage>
              )}
            </Flex>
          </Flex>
        </Flex>
        <UploadButton
          fileName={[...values.upload_files]?.map((item) => item.name)}
          uploadRef={uploadInput}
          onChange={props.handleUploadFile}
        />
        <Flex gap="small" vertical>
          <LabelTitle>Tarih Aralıgı</LabelTitle>
          <Flex justify="space-between" gap="small">
            <Flex vertical gap="small" className={styles.widthHalf}>
              <DatePicker
                id="start_date"
                name="start_date"
                format={DATE_FORMAT}
                value={
                  values.start_date
                    ? dayjs(values.start_date, DATE_FORMAT)
                    : null
                }
                className={styles.input}
                placeholder="Başlangıç Tarihi"
                onChange={(date) => {
                  if (date) {
                    setFieldValue('start_date', date.format(DATE_FORMAT));
                  }
                }}
              />
              {touched.start_date && errors.start_date && (
                <ErrorMessage>{errors.start_date}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={styles.widthHalf}>
              <DatePicker
                id="end_date"
                name="end_date"
                format={DATE_FORMAT}
                value={
                  values.end_date ? dayjs(values.end_date, DATE_FORMAT) : null
                }
                className={styles.input}
                placeholder="Bitiş Tarihi"
                onChange={(date) => {
                  if (date) {
                    setFieldValue('end_date', date.format(DATE_FORMAT));
                  }
                }}
              />
              {touched.end_date && errors.end_date && (
                <ErrorMessage>{errors.end_date}</ErrorMessage>
              )}
            </Flex>
          </Flex>
        </Flex>
        {props.type === 'EDIT' && (
          <Flex justify="space-between">
            <LabelTitle>Durum Bilgisi</LabelTitle>
            <StatusDropdown
              status={values.recent_status}
              statusChoices={props.statusChoices}
              onChange={(value) => setFieldValue('recent_status', value)}
            />
          </Flex>
        )}
        {props.type === 'EDIT' && (
          <MailCheckbox
            checked={values.email_permission}
            onChange={(e: CheckboxChangeEvent) =>
              setFieldValue('email_permission', e.target.checked)
            }
          />
        )}
      </Flex>
    </ModalDialog>
  );
};

export default OtherInsuranceModal;
