import { FC, useEffect, useState } from 'react';
import { Flex, Select } from 'antd';
import { IProps } from './type';
import styles from './index.module.scss';
import { AuthorizedButton } from '../../../../../components';
import { formatISOString } from '../../../../../utils/functions';

const ProfileCard: FC<IProps> = (props) => {
  const { Option } = Select;

  const [selectedId, setSelectedId] = useState<number | null>(null);

  const handleChange = (id: number) => {
    props.handleChangeRole(id);
    setSelectedId(id);
  };

  const handleClickDelete = () => {
    props.handleClickDelete();
    setSelectedId(null);
  };

  useEffect(() => {
    props.roles[0]?.id && handleChange(props.roles[0].id);
  }, [props.roles[0]?.id]);

  return (
    <Flex vertical justify="space-between" className={styles.container}>
      <Flex vertical gap={24}>
        <Select
          value={selectedId}
          onSelect={handleChange}
          className={styles.select}
        >
          {props.roles.map((role) => (
            <Option key={role.id} value={role.id}>
              {role.external_name}
            </Option>
          ))}
        </Select>
        {props.selectedItem?.external_name && (
          <Flex justify="space-between">
            <span className={styles.label}>Profil Adı:</span>
            <span className={styles.value}>
              {props.selectedItem?.external_name}
            </span>
          </Flex>
        )}
        {props.selectedItem?.created_at && (
          <Flex justify="space-between">
            <span className={styles.label}>Oluşturulma Tarihi:</span>
            <span className={styles.value}>
              {props.selectedItem.created_at
                ? formatISOString(props.selectedItem.created_at)
                : null}
            </span>
          </Flex>
        )}
        {props.selectedItem?.created_by && (
          <Flex justify="space-between">
            <span className={styles.label}>Oluşturan Kullanıcı:</span>
            <span className={styles.value}>
              {props.selectedItem.created_by}
            </span>
          </Flex>
        )}
        <Flex justify="space-between">
          <span className={styles.label}>Bağlı Kullanıcı Sayısı:</span>
          <span className={styles.value}>
            {props.selectedItem?.connected_users}
          </span>
        </Flex>
      </Flex>
      <Flex justify="space-between" gap={8}>
        <AuthorizedButton
          model="extendedgroup"
          action="delete"
          danger
          className={styles.button}
          onClick={handleClickDelete}
        >
          Sil
        </AuthorizedButton>
        <AuthorizedButton
          model="extendedgroup"
          action="change"
          className={styles.button}
          onClick={props.handleClickEdit}
        >
          Güncelle
        </AuthorizedButton>
      </Flex>
    </Flex>
  );
};

export default ProfileCard;
