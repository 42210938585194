import { useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import {
  tableReducer,
  notificationReducer,
  userReducer,
  layoutReducer,
} from './reducers';

const store = configureStore({
  reducer: {
    tableReducer,
    notificationReducer,
    userReducer,
    layoutReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export default store;
